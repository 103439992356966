exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-headcard-jsx": () => import("./../../../src/templates/Headcard.jsx" /* webpackChunkName: "component---src-templates-headcard-jsx" */),
  "component---src-templates-profile-jsx": () => import("./../../../src/templates/Profile.jsx" /* webpackChunkName: "component---src-templates-profile-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/Search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-shortlist-jsx": () => import("./../../../src/templates/Shortlist.jsx" /* webpackChunkName: "component---src-templates-shortlist-jsx" */)
}

